import React, { UIEvent } from 'react';
import './App.css';
import ConfirmSignature from './../pages/ConfirmSignature';
import PdfReader from './../pages/PdrReader';
import EnterCode from '../pages/EnterCode';
import WrongCode from '../pages/WrongCode';

const baseUrl = "https://sunconnect.net";

class App extends React.Component {
  state={
    isLoading: true,
    isError: false,
    pdfLink: "",
    isValidRead: false,
    isContractAccept: false,
    isSmsCodeValid: false,
    smsSendLink: "",
    signLink: "",
    wrongCode: false
  }

  componentDidMount(): void {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    
    fetch(baseUrl + "/client/signature-module!" + params.id + "/" + params.link + "/" + params.type).then(async (r : Response) => {
      var response = await r.json();
      console.log(response); 
      this.setState({ 
        pdfLink: baseUrl + "/" + response.pdf_link,
        isLoading: false,
        isValidRead: false,
        smsSendLink: baseUrl + response.sms_send_link,
        signLink: baseUrl + response.sign_link,
      });
    }).catch((e) => this.setState({isError: true}))
  }

  sendSMS() {
    this.setState({isLoading: true});
    fetch(this.state.smsSendLink)
      .then(async (r : Response) => this.setState({isLoading: false}))
      .catch((e) => this.setState({isError: true}))
  }

  signFile(code: string) {
    this.setState({isLoading: true});
    fetch(this.state.signLink + code)
      .then(async (r : Response) => {
        var res = await r.json();
        this.setState({isLoading: false, isSmsCodeValid: true});
        if (res.signed == false)
          this.setState({wrongCode: true, isSmsCodeValid: false});
      })
      .catch((e) => this.setState({isError: true}))
  }
 
  render() { 
    if (this.state.isLoading)
      return (
        <div className="App">
          <img width="20%" src="./logo_min.png" style={{ height: "100%", margin: "20px" }} />
          <p className='px-5' style={{color: '#051249', fontWeight: 'bold'}}>
            {this.state.isError == true ? "OUPS ! VOUS NE POUVEZ PAS ACCÉDER À CE CONTRAT." : "CHARGEMENT..."}
          </p>
        </div>
      );
    if (!this.state.isValidRead)
      return (
        <div className="App">
          <PdfReader onReadValid={() => this.setState({isValidRead : true})} pdfLink={this.state.pdfLink}/>
        </div>
      );

    if (!this.state.isContractAccept)
      return (
        <div className="App">
          <ConfirmSignature onAccept={() => {
            this.sendSMS();
            this.setState({isContractAccept : true});
          }} />
        </div>
      ); 

    if (this.state.wrongCode)
      return (
        <div className="App">
          <WrongCode onAccept={() => {
            this.setState({wrongCode : false});
          }} />
        </div>
      );

    if (!this.state.isSmsCodeValid)
      return (
        <div className="App">
          <EnterCode onAccept={(code: string) => {
            this.signFile(code);
            this.setState({isSmsCodeValid : true});
          }} />
        </div>
      );
    return (
      <div className="App">
        <img width="20%" src="./logo_min.png" style={{ height: "100%", margin: "20px" }} />
        <p style={{color: '#051249', fontWeight: 'bold'}}>
          Processus terminé !<br></br> Merci de faire confiance à sunconnect.
        </p>
      </div>
    );
  }
}

export default App;
