import React from "react";
import '../index.css';

class ConfirmSignature extends React.Component<{ onAccept: Function }> {
  state = {
    isValid: false
  }

  render(): React.ReactNode {
      return (
        <div className="vh-100 vw-100 d-flex flex-column" style={{ backgroundColor: 'white'}} >
          <div /* HEADER */ style={{ borderBottom: '3px solid black', height: '80px', backgroundColor: 'white' }}>
            <div className="row h-100 m-0">
              <div className="col-6 h-100 d-flex justify-content-start align-content-center">
                <h1 className="m-0 align-self-center" style={{ color:'#051249', fontSize: '38px', paddingLeft: '20px'  }}>
                    Confirmation
                </h1>      
              </div>
              <div className="col-6 h-100 d-flex justify-content-end">
                <img src="./logo_min.png" style={{ height: "100%", padding: "20px" }} />
              </div>
            </div>
          </div>
          <div className="p-4 d-flex" style={{ flex: 1 }}>
            <div className="row">
              <input onChange={(e) => this.setState({ isValid: (e.currentTarget.checked) })} className="m-2 form-check-input" type="checkbox" style={{ accentColor: '#051249', width: '25px', height: '25px' }} />
              <label className="col form-check-label px-2" style={{ textAlign: 'left', color: 'black' }}>
                Je confirme avoir lu, compris et accepter ce contrat.
              </label>
            </div>
          </div>
          <div /* ACTION */ style={{ position:'relative',  borderTop: '3px solid black', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '80px', backgroundColor: 'white' }}>
            <button disabled={!this.state.isValid} onClick={() => this.props.onAccept()} type="button" className="btn btn-primary">SIGNER DIGITATLEMENT</button>
          </div>
        </div> 
      );
  }
}

export default ConfirmSignature;