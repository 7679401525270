import React, { UIEvent } from 'react';
import '../app/App'
import { Document, Page, pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class PdfReader extends React.Component<{ pdfLink : string, onReadValid: Function }> {
  state = {
    actualPage : 1,
    maxPages : 1,
    widthPage : 0,
    scrollMax: 10,
    scrollNow: 0,
    canSkipPage : false,
    showMessageScroll: false,
    isLoading: true
  }; 

  onUpdate = () => {
    if (this.state.isLoading || this.state.canSkipPage) {
      this.setState({
        widthPage: window.innerWidth,
      });
      return ;
    }
    this.setState({ 
      widthPage: window.innerWidth,
      scrollNow: (document.querySelector("#pdf_in")?.scrollTop || 0) + (document.querySelector("#pdf_in")?.clientHeight || 0),
      scrollMax: document.querySelector("#pdf_in")?.scrollHeight,
    });
    setTimeout(() => {
      if (!this.state.isLoading && this.state.scrollMax == this.state.scrollNow)
        this.setState({ canSkipPage : true })
      else if (!this.state.isLoading)
        this.setState({showMessageScroll : true});
    }, 1000)
  }
  
  componentDidMount() : void {
    window.addEventListener('resize', this.onUpdate);
    this.onUpdate();
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.onUpdate);
  }
  
  setMaxPages = ({ numPages } : {numPages : number}) => {
    this.setState({ maxPages : numPages, canSkipPage : false });
    this.onPageLoadSuccess();
  }


  handleScroll = (event: UIEvent<HTMLDivElement>) => {
    setTimeout(() => this.onUpdate(), 100);
  };

  onNextPage() {
    this.setState({
      actualPage : this.state.actualPage + 1,
      canSkipPage : false,
      isLoading : true,
      showMessageScroll : false
    })
  }

  onPreviousPage() {
    this.setState({
      actualPage : this.state.actualPage - 1,
      canSkipPage : false,
      isLoading : true,
      showMessageScroll : false
    })
  }

  onPageLoadSuccess() {
    setTimeout(() => {
      this.setState({ isLoading : false })
      setTimeout(() => this.onUpdate(), 100);
    }, 1500);
  }

  render() {
    console.log(this.state.isLoading)
    console.log(this.state.canSkipPage)
    console.log((this.state.canSkipPage || this.state.isLoading));
    return (
        <div className="vh-100 vw-100 d-flex flex-column" style={{ backgroundColor: 'grey'}} >
          <div /* HEADER */ style={{ borderBottom: '3px solid black', height: '80px', backgroundColor: 'white' }}>
            <div className="row h-100 m-0">
              <div className="col-6 h-100 d-flex justify-content-start align-content-center">
                <h1 className="m-0 align-self-center" style={{ color:'#051249', fontSize: '38px', paddingLeft: '20px'  }}>
                    Devis
                </h1>      
              </div>
              <div className="col-6 h-100 d-flex justify-content-end">
                <img src="./logo_min.png" style={{ height: "100%", padding: "20px" }} />
              </div>
            </div>
          </div>
          <div id='pdf_reader' className='d-flex' style={{ flex: 1, overflowY: 'hidden' }}>
            <div id='pdf_in'className='w-100 d-flex' onScroll={this.handleScroll} style={{ overflowY: 'scroll' }}>
              <Document onLoadProgress={() => this.setState({ canSkipPage : false }) } className='m-auto' onLoadSuccess={this.setMaxPages} file={this.props.pdfLink}>
                <Page onLoadSuccess={() => this.onPageLoadSuccess()} pageNumber={this.state.actualPage} width={this.state.widthPage < 800 ? this.state.widthPage : 800 } />
              </Document>
            </div>
          </div>
          <div /* ACTION */ style={{ position:'relative',  borderTop: '3px solid black', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '80px', backgroundColor: 'white' }}>
            <button type="button" 
              onClick={() => this.onPreviousPage()}
              className="btn btn-primary" 
              style={{
                marginRight: '10px',
                display: this.state.actualPage != 1 ? 'block' : 'none'
              }}>{"<"}</button>
            <button disabled={!this.state.canSkipPage} onClick={() => (this.state.actualPage == this.state.maxPages) ? this.props.onReadValid() : this.onNextPage()} type="button" className="btn btn-primary">{(this.state.actualPage == this.state.maxPages) ? "TERMINER" : "PAGE SUIVANTE"}</button>
            <div
              className='p-2'
              style={{
                backgroundColor: '#000000C0',
                bottom: '100px',
                position: 'fixed',
                opacity: (!this.state.showMessageScroll || this.state.canSkipPage || this.state.isLoading) ? 0 : 1
              }}
            >
              <p className='m-0 p-0 h6'>Veuillez faire défiler pour pouvoir passer à la page suivante</p>
            </div>
          </div>
        </div>  
    );
  }
}

export default PdfReader;
