import React from "react";
import '../index.css';

class WrongCode extends React.Component<{ onAccept: Function }> {
  state = {
    isValid: false
  }

  render(): React.ReactNode {
      return (
        <div className="vh-100 vw-100 d-flex flex-column" style={{ backgroundColor: 'white'}} >
          <div /* HEADER */ style={{ borderBottom: '3px solid black', height: '80px', backgroundColor: 'white' }}>
            <div className="row h-100 m-0">
              <div className="col-6 h-100 d-flex justify-content-start align-content-center">
                <h1 className="m-0 align-self-center" style={{ color:'#051249', fontSize: '38px', paddingLeft: '20px'  }}>
                    Erreur
                </h1>      
              </div>
              <div className="col-6 h-100 d-flex justify-content-end">
                <img src="./logo_min.png" style={{ height: "100%", padding: "20px" }} />
              </div>
            </div>
          </div>
          <div className="p-4 d-flex" style={{ flex: 1 }}>
              <p style={{ color: '#051249', textAlign: 'start' }}>
                Le code entré est erroné.
                <br></br><br></br>
                Veuillez réessayer.
              </p>
          </div>
          <div /* ACTION */ style={{ position:'relative',  borderTop: '3px solid black', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '80px', backgroundColor: 'white' }}>
            <button onClick={() => this.props.onAccept()} type="button" className="btn btn-primary">RECOMMENCER</button>
          </div>
        </div> 
      );
  }
}

export default WrongCode;