import React from "react";
import '../index.css';

class EnterCode extends React.Component<{ onAccept: Function }> {
  state = {
    code: ""
  }

  render(): React.ReactNode {
      return (
        <div className="vh-100 vw-100 d-flex flex-column" style={{ backgroundColor: 'white'}} >
          <div /* HEADER */ style={{ borderBottom: '3px solid black', height: '80px', backgroundColor: 'white' }}>
            <div className="row h-100 m-0">
              <div className="col-6 h-100 d-flex justify-content-start align-content-center">
                <h1 className="m-0 align-self-center" style={{ color:'#051249', fontSize: '38px', paddingLeft: '20px'  }}>
                    Confirmation
                </h1>      
              </div>
              <div className="col-6 h-100 d-flex justify-content-end">
                <img src="./logo_min.png" style={{ height: "100%", padding: "20px" }} />
              </div>
            </div>
          </div>
          <div className="p-4 d-flex align-items-start col" style={{ flex: 1, color: '#051249', flexDirection: 'column' }}>
            <p style={{ textAlign: 'start' }}>
              Un code permettant la signature digitale vous a été envoyé par sms. <br></br>
              <br></br>
              Il devrait arriver dans quelques instant.
            </p>
            <input placeholder="Entrez le code ici." onChange={(e) => this.setState({ code: (e.currentTarget.value) })} className="my-2 form-control" type="number" style={{ accentColor: '#051249',}} />
          </div>
          <div /* ACTION */ style={{ position:'relative',  borderTop: '3px solid black', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '80px', backgroundColor: 'white' }}>
            <button disabled={this.state.code.length < 5} onClick={() => this.props.onAccept(this.state.code)} type="button" className="btn btn-primary">SIGNER DIGITATLEMENT</button>
          </div>
        </div> 
      );
  }
}

export default EnterCode;